import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

import '../scss/app.scss';

import Swiper, { Navigation, Pagination, Autoplay, EffectFade } from 'swiper';

Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);

const isHome = document.querySelector('body').classList.contains('home');

const service = new Swiper('#service__swiper .swiper', {
  lazy: true,
  autoplay: false,
  loop: true,
  centeredSlides: true,
  slidesPerView: 'auto',
  spaceBetween: 20,
  navigation: {
    prevEl: '#service__swiper-navigation-prev',
    nextEl: '#service__swiper-navigation-next',
  },
  breakpoints: {
    600: {
      spaceBetween: 40,
    }
  }
});

const casestudy = new Swiper('#case__swiper .swiper', {
  lazy: true,
  // autoplay: isHome,
  autoplay: false,
  loop: true,
  slidesPerView: 'auto',
  spaceBetween: 20,
  centeredSlides: true,
  navigation: {
    prevEl: '#case__swiper-navigation-prev',
    nextEl: '#case__swiper-navigation-next',
  },
  breakpoints: {
    600: {
      spaceBetween: 40,
    }
  }
});

const info = new Swiper('#info__swiper .swiper', {
  lazy: true,
  autoplay: false,////////
  loop: true,
  slidesPerView: 'auto',
  spaceBetween: 20,
  centeredSlides: true,
  navigation: {
    prevEl: '#info__swiper-navigation-prev',
    nextEl: '#info__swiper-navigation-next',
  },
  breakpoints: {
    600: {
      spaceBetween: 40,
    }
  }
});

const company = new Swiper('#company__swiper .swiper', {
  speed: 9000,
  allowTouchMove: false,
  lazy: true,
  autoplay: {
    delay: 0
  },
  loop: true,
  slidesPerView: 'auto',
  spaceBetween: 0,
});

const employ = new Swiper('#employ__swiper .swiper', {
  speed: 9000,
  allowTouchMove: false,
  lazy: true,
  autoplay: {
    delay: 0
  },
  loop: true,
  slidesPerView: 'auto',
  spaceBetween: 0,
});

const voice = new Swiper('#voice__swiper .swiper', {
  lazy: true,
  autoplay: isHome,
  loop: true,
  slidesPerView: 'auto',
  spaceBetween: 20,
  centeredSlides: true,
  navigation: {
    prevEl: '#voice__swiper-navigation-prev',
    nextEl: '#voice__swiper-navigation-next',
  },
  breakpoints: {
    600: {
      loop: false,
      watchOverflow: true,
      centeredSlides: false,
      spaceBetween: 40,
    }
  }
});

const consultant = new Swiper('#consultant__swiper .swiper', {
  lazy: true,
  autoplay: isHome,
  loop: true,
  slidesPerView: 'auto',
  spaceBetween: 20,
  centeredSlides: true,
  navigation: {
    prevEl: '#consultant__swiper-navigation-prev',
    nextEl: '#consultant__swiper-navigation-next',
  },
  breakpoints: {
    600: {
      spaceBetween: 40,
    }
  }
});

const tutor = new Swiper('#tutor__swiper .swiper', {
  lazy: true,
  autoplay: false,
  loop: true,
  slidesPerView: 'auto',
  spaceBetween: 20,
  centeredSlides: true,
  navigation: {
    prevEl: '#tutor__swiper-navigation-prev',
    nextEl: '#tutor__swiper-navigation-next',
  },
  breakpoints: {
    600: {
      spaceBetween: 40,
    }
  }
});

const messages = new Swiper('#messages__swiper .swiper', {
  lazy: true,
  autoplay: true,
  loop: true,
  slidesPerView: 'auto',
  spaceBetween: 20,
  centeredSlides: true,
  navigation: {
    prevEl: '#messages__swiper-navigation-prev',
    nextEl: '#messages__swiper-navigation-next',
  },
  breakpoints: {
    600: {
      spaceBetween: 40,
    }
  }
});

const feature = new Swiper('#feature__swiper .swiper', {
  speed: 9000,
  allowTouchMove: false,
  lazy: true,
  autoplay: {
    delay: 0
  },
  loop: true,
  slidesPerView: 1.2,
  spaceBetween: 5,
  breakpoints: {
    600: {
      slidesPerView: 'auto',
      spaceBetween: 10,
    }
  }
});

document.addEventListener("DOMContentLoaded", function() {
  document.querySelector('html').classList.add('is-loaded');

  var lazyloadImages;    

  if ("IntersectionObserver" in window) {
    lazyloadImages = document.querySelectorAll(".is-lazy");
    var imageObserver = new IntersectionObserver(function(entries, observer) {
      entries.forEach(function(entry) {
        if (entry.isIntersecting) {
          var image = entry.target;
          image.classList.remove("is-lazy");
          imageObserver.unobserve(image);
        }
      });
    });

    lazyloadImages.forEach(function(image) {
      imageObserver.observe(image);
    });
  } else {  
    var lazyloadThrottleTimeout;
    lazyloadImages = document.querySelectorAll(".is-lazy");
    
    function lazyload () {
      if(lazyloadThrottleTimeout) {
        clearTimeout(lazyloadThrottleTimeout);
      }    

      lazyloadThrottleTimeout = setTimeout(function() {
        var scrollTop = window.pageYOffset;
        lazyloadImages.forEach(function(img) {
            if(img.offsetTop < (window.innerHeight + scrollTop)) {
              img.src = img.dataset.src;
              img.classList.remove('is-lazy');
            }
        });
        if(lazyloadImages.length == 0) { 
          document.removeEventListener("scroll", lazyload);
          window.removeEventListener("resize", lazyload);
          window.removeEventListener("orientationChange", lazyload);
        }
      }, 20);
    }

    document.addEventListener("scroll", lazyload);
    window.addEventListener("resize", lazyload);
    window.addEventListener("orientationChange", lazyload);
  }
})

+function(){
  const html = document.querySelector('html');
  const menu = document.getElementById('menu');
  const menuControl = document.getElementById('menu__control');
  const menuOpen = document.getElementById('menu__open');
  const menuClose = document.getElementById('menu__close');
  const menuCloseSp = document.getElementById('menu__close--sp');

  // page link
  function scrollToSmoothly(ev) {
    ev.preventDefault();

    if (html.classList.contains('menu-open')) {
      html.classList.remove('menu-open')
    }

    const href = ev.target.getAttribute('href');
    if (!href || !/^#/.test(href)) return;
    const id = href.substr(1);
    const el = document.getElementById(id);
    const menu = document.getElementById('menu');
    const styles = window.getComputedStyle(menu);
    const offset = '0px' == styles.top ? menu.offsetHeight : 0;
    if (el) {
      window.scrollTo({
        behavior: 'smooth',
        top:
          el.getBoundingClientRect().top -
          document.body.getBoundingClientRect().top -
          offset,
      })
    }
  }
  document.querySelectorAll('[href^="#"]').forEach(el => {
    el.addEventListener('click', ev => scrollToSmoothly(ev));
  });

  // modal
  document.querySelectorAll('[data-modal^="#"]').forEach(el => {
    el.addEventListener('click', ev => {
      ev.preventDefault();
      if (document.querySelector('html').classList.contains('is-popup')) return;

      const id = ev.target.dataset.modal.substr(1)
       console.log(id)
      document.getElementById(id).classList.add('is-active')
      document.querySelector('html').classList.add('is-popup')
    });
  });
  document.querySelectorAll('[data-modal="close"]').forEach(el => {
    el.addEventListener('click', ev => {
      ev.preventDefault();
      document.querySelector('html').classList.remove('is-popup')
      document.querySelectorAll('.modal.is-active').forEach(el => {
        el.classList.remove('is-active')
      })
    });
  });

  // show/hide underlayer links on menu/sitemap
  document.querySelectorAll('.sitemap__link.is-group .action').forEach(el => {
    el.addEventListener('click', ev => {
      ev.preventDefault();
      ev.target.closest('.is-group').classList.toggle('is-open')
    });
  });
  document.querySelectorAll('.menu__link.is-group .action').forEach(el => {
    el.addEventListener('click', ev => {
      ev.preventDefault();
      ev.target.closest('.is-group').classList.toggle('is-open')
    });
  });

  // on scroll
  let lastKnownScrollPosition = 0;
  let ticking = false;
  function menuOnScroll(scrollPos) {
    if (isPc()) {
      if (1200 < scrollPos) {
        menu.classList.add('is-active');
      } 
      else if (scrollPos < 1590) {
        menu.classList.remove('is-active');      
      }
    } else {
      if (60 < scrollPos) {
        menu.classList.add('is-active');
      } 
      else if (scrollPos < 85) {
        menu.classList.remove('is-active');      
      }  
    }
    if (10 <= scrollPos) {
      html.classList.add('is-scrolled');
    } 
    else if (scrollPos < 10) {
      html.classList.remove('is-scrolled');      
    }  
  }
  document.addEventListener('scroll', (event) => {
    lastKnownScrollPosition = window.scrollY;
  
    if (!ticking) {
      window.requestAnimationFrame(() => {
        menuOnScroll(lastKnownScrollPosition);
        ticking = false;
      });
  
      ticking = true;
    }
  });

  // menu
  menuOpen.addEventListener('click', ev => {
    html.classList.add('menu-open')
  });
  menuClose.addEventListener('click', ev => {
    html.classList.remove('menu-open')
  });
  menuCloseSp.addEventListener('click', ev => {
    html.classList.remove('menu-open')
  });

  // helper
  const isHidden = elem => {
    const styles = window.getComputedStyle(elem)
    return styles.display === 'none' || styles.visibility === 'hidden'
  }
  const isPc = elem => {
    return isHidden(menuControl)
  }
}();
